<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
  <!-- End Slider Area -->

    <!-- Start About Area -->
    <!-- End About Area -->


    <!-- Start Portfolio Area -->
    <div class="portfolio-area ptb--120 bg_color--1">
      <div class="portfolio-wrapper">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title">
                <span class="subtitle">DLab</span>
                <h2 class="heading-title">Introduce</h2>
                <p class="description">
                  (주)데이터엔지니어스랩을 소개합니다.
                </p>
              </div>
            </v-col>
          </v-row>
          <Portfolio />
        </v-container>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Service Area -->
    <div class="service-area ptb--120 bg_color--5">
      <v-container>
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">DLab</span>
              <h2 class="heading-title">Products</h2>
              <p class="description">
                (주)데이터엔지니어스랩의 노하우로 만든 제품을 소개합니다.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceTwo />
      </v-container>
    </div>
    <!-- End Service Area -->

    <!-- Start Brand Area -->
    <div class="rn-brand-area ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title mb--30">
              <span class="subtitle">DLab</span>
              <h2 class="heading-title">Customers</h2>
              <p class="description">
                (주)데이터엔지니어스랩과 함께 합니다.
              </p>
            </div>
          </v-col>
        </v-row>
        <Brand />
      </v-container>
    </div>
    <!-- End  Brand Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import SliderOne from "../../components/slider/SliderOne";
  import About from "../../components/about/About";
  import ServiceTwo from "../../components/service/ServiceTwo";
  import Portfolio from "../../components/portfolio/Portfolio";
  import CounterOne from "../../components/counter/CounterOne";
  import Testimonial from "../../components/testimonial/Testimonial";
  import Blog from "../../components/blog/Blog";
  import Brand from "../../components/brand/Brand";
  import Footer from "../../components/footer/Footer";

  export default {
    components: {
      Header,
      SliderOne,
      About,
      ServiceTwo,
      Portfolio,
      CounterOne,
      Testimonial,
      Blog,
      Brand,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
      };
    },
  };
</script>
